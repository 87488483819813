import { EngagementOptionEnumValues, MarketMakerEngagementsStatusEnumValues } from '@forgd/supabase'
import { z } from 'zod'
import { BasicKpiResultSchema, KpiResultSchema } from '../kpi'

const MmEngagementSchema = z.object({
  id: z.string().uuid(),
  firstReportReceived: z.string().nullable(),
  lastReportReceived: z.string().nullable(),
  projectId: z.string().uuid(),
  projectImage: z.string().nullable(),
  projectName: z.string(),
  projectTicker: z.string().nullable(),
  status: z.enum(MarketMakerEngagementsStatusEnumValues),
  totalReports: z.number(),
  type: z.enum(EngagementOptionEnumValues),
})

export const GetMmEngagementsQuerySchema = z.object({
  statuses: z.array(z.enum(MarketMakerEngagementsStatusEnumValues)),
})

export const GetMmEngagementsResponseBody = z.array(MmEngagementSchema)

const MmEngagementRequestBody = z.object({
  coingeckoId: z.string().min(1, 'Coingecko ID is required'),
  engagementType: z.enum(EngagementOptionEnumValues),
  loanTokenQty: z.number(),
  loanStablecoinQty: z.number().nullable(),
  uptimePercentage: z.number().nullable(),
  percentOfLiquidity: z.number().nullable(),
})

const BasicMmEngagementSchema = MmEngagementRequestBody.extend({
  kpiType: z.literal('basic'),
  kpis: BasicKpiResultSchema.nullable(),
})

const AdvancedMmEngagementSchema = MmEngagementRequestBody.extend({
  kpiType: z.literal('advanced'),
  kpis: z.array(KpiResultSchema),
})

export const CreateMmEngagementRequestBody = z.discriminatedUnion('kpiType', [
  BasicMmEngagementSchema,
  AdvancedMmEngagementSchema,
  MmEngagementRequestBody.extend({
    kpiType: z.null(),
    kpis: z.null(),
  }),
])

export const CreateMmEngagementResponseBody = z.object({
  message: z.string(),
})

export type GetMmEngagementsResponseBody = z.infer<typeof GetMmEngagementsResponseBody>
export type CreateMmEngagementResponseBody = z.infer<typeof CreateMmEngagementResponseBody>
export type CreateMmEngagementRequestBody = z.infer<typeof CreateMmEngagementRequestBody>
