import { z } from 'zod'
import { MemberInvitationDto } from '../membership'
import {
  AreasOfInterestEnumValues,
  BaseLayerTypeEnumValues,
  MarketMakerRequestStatusEnumValues,
  SectorTypeEnumValues,
} from '@forgd/supabase'
import { datetimeLocal } from '@forgd/shared/utils/zod'
import { FORGD_SYSTEM_COINGECKO_ID } from '@forgd/shared/constants'

export const TProject = z.object({
  id: z.string().uuid(),
  hasToken: z.boolean(),
  isResearch: z.boolean(),
  tokenListed: z.boolean(),
  name: z.string(),
  ticker: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  image: z.string().nullable(),
  link: z.string().nullable(),
  organizationId: z.string().uuid().nullable().optional(),
  tgeDate: z.string().date().nullable(),
  areasOfInterest: z.array(z.enum(AreasOfInterestEnumValues)).nullable().optional(),
  sector: z.array(z.enum(SectorTypeEnumValues)).nullable().optional(),
  baseLayer: z.array(z.enum(BaseLayerTypeEnumValues)).nullable().optional(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable().optional(),
})

export const TUpdateProjectBody = z.object({
  hasToken: z.boolean().optional(),
  tokenListed: z.boolean().optional(),
  name: z.string().trim().min(1).optional(),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase())
    .optional(),
  coingeckoId: z
    .string()
    .nullable()
    .optional()
    .refine((val) => val?.trim() !== FORGD_SYSTEM_COINGECKO_ID, 'Coingecko cannot be system token'),
  image: z.string().url().optional().nullable(),
  link: z.string().url().optional(),
  organizationId: z.string().uuid().optional(),
  tgeDate: z.string().date().optional(),
  areasOfInterest: z.array(z.enum(AreasOfInterestEnumValues)).nullable().optional(),
  sector: z.array(z.enum(SectorTypeEnumValues)).nullable().optional(),
  baseLayer: z.array(z.enum(BaseLayerTypeEnumValues)).nullable().optional(),
})

const TProjectCreate = TProject.omit({
  id: true,
  coingeckoId: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})

export const TProjectCreateBody = TProjectCreate.extend({
  members: z.array(MemberInvitationDto).optional(),
  tokenListed: z.boolean().optional(),
})

export const TProjects = z.object({
  items: z.array(TProject),
})

const TokenPrices = z.object({
  price: z.number(),
  priceChangePercentage24h: z.number(),
  marketChartPrice24h: z.array(
    z.object({
      date: z.number(),
      value: z.number(),
    }),
  ),
})

export type TokenPrices = z.infer<typeof TokenPrices>

const MmReceivedQuotes = z.object({
  round1: z.number(),
  round2: z.number(),
})

export type MmReceivedQuotes = z.infer<typeof MmReceivedQuotes>

export const ProjectDashboardMetricsResponse = z.object({
  projectName: z.string(),
  projectTicker: z.string(),
  tgeDate: z.string().date(),
  baseLayer: z.array(z.enum(BaseLayerTypeEnumValues)),
  isTokenListed: z.boolean(),
  isPublicPagePublished: z.boolean(),
  activeMembers: z.number(),
  mmRfqStatus: z.enum(MarketMakerRequestStatusEnumValues),
  mmEngagements: z.array(
    z.object({
      id: z.string(),
      imageUrl: z.string().nullable(),
    }),
  ),
  mmRfqLastReportedAt: z.string().date().nullable(),
  mmReceivedQuotes: MmReceivedQuotes,
  tokenPrices: TokenPrices.optional(),
})

export type ProjectDashboardMetrics = z.infer<typeof ProjectDashboardMetricsResponse>
