import { z, ZodIssueCode } from 'zod'
import { MetaSchema } from '../calculator'
import { PriceDataSchema, TokenInfoResult } from '../token-unlock'
import {
  BaseLayerTypeEnumValues,
  EngagementOptionEnumValues,
  EngagementOptionEnum,
  ExchangeTypeEnumValues,
  MarketMakerQuoteKpiMetricEnumValues,
  MarketMakerQuoteLoanCustodyEnumValues,
  MarketMakerQuoteStatusEnumValues,
  MarketMakerQuoteTranchePriceMethodologyEnumValues,
  MarketMakerRequestStatusEnumValues,
  OptionsStyleEnumValues,
  ContactTypeEnumValues,
  type Database,
} from '@forgd/supabase'
import { isFloatGreaterWithPrecision, isFloatLessWithPrecision } from '@forgd/shared/utils/numbers'
import { likertScaleTypeSchema } from '../common'
import { KpiResultSchema } from '../kpi'

export const MMRFQTranchePriceFlagArray = ['discount', 'par', 'premium'] as const

export enum MMRFQTranchePriceFlagEnum {
  Discount = 'discount',
  Par = 'par',
  Premium = 'premium',
}

export interface MarketMakerRFQOption {
  value: string
  label: string
  description?: string
  engagementDescription?: string
  loanRequirements?: string[]
}

export const OptionResult = z.object({
  value: z.string(),
  label: z.string(),
})

export type Investor = z.infer<typeof OptionResult>

export const InvestorsResult = z.array(OptionResult)

export const RFQProjectDetailsResult = z.object({
  id: z.string().uuid(),
  isResearch: z.boolean(),
  ticker: z.string().nullable(),
  tokenListed: z.boolean().nullable().default(false),
  link: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  isTdPublished: z.boolean(),
  hasRFQ: z.boolean(),
  tgeDate: z.string().nullable(),
  image: z.string().nullable(),
  name: z.string().nullable(),
  baseLayer: z.array(z.enum(BaseLayerTypeEnumValues)).nullable(),
  hasEngagedMarketMakers: z.boolean(),
})
export type RFQProjectDetails = z.infer<typeof RFQProjectDetailsResult>

export const RFQTokenDesignerDetailsResult = z.object({
  maxTokenSupply: z.number().nullable(),
  fdvAtTge: z.number().nullable(),
  tokenPriceAtTGE: z.number().nullable(),
  percentageUnlockedAtTGE: z.number(),
  circulatingSupply: z.number().nullable(),
  marketCapAtTge: z.number().nullable(),
})

export type RFQTokenDesignerDetails = z.infer<typeof RFQTokenDesignerDetailsResult>

export const RFQTokenMarketDetailsResult = TokenInfoResult.omit({
  tgeDate: true,
}).merge(
  z.object({
    fdv: z.number(),
    circulatingSupply: z.number(),
    marketCap: z.number().nullable(),
    price: z.number(),
    priceChangePercentage24h: z.number(),
    marketChartPrice24h: z
      .object({
        data: z.array(PriceDataSchema),
        meta: MetaSchema,
      })
      .nullable(),
  }),
)

export type RFQTokenMarketDetails = z.infer<typeof RFQTokenMarketDetailsResult>

export const RFQStatusItemResult = z.object({
  status: z.enum(MarketMakerRequestStatusEnumValues),
  date: z.string(),
})

export type RFQStatusItem = z.infer<typeof RFQStatusItemResult>

export const RFQResult = z.object({
  status: z.enum(MarketMakerRequestStatusEnumValues),
  statusUpdatedAt: z.string(),
  marketMakersQty: z.number().min(1).max(4),
  engagementOptions: z.array(z.enum(EngagementOptionEnumValues)).min(1).nullable(),
  tokenSupplyPercentageToAllocate: z.string().min(1),
  stableCoinBudgetToAllocate: z.string().min(1).nullable(),
  motivations: z.array(z.string()),
  exchangesCEX: z.array(z.string()),
  exchangesDEX: z.array(z.string()),
  externalCapital: z.string().nullable(),
  investors: z.array(z.string()).optional(),
  country: z.string().min(2),
  hasLegalOpinionOnUtilityStatus: z.boolean(),
})

export const RFQDetailsResult = z.object({
  project: RFQProjectDetailsResult,
  tokenDesignerDetails: RFQTokenDesignerDetailsResult.optional(),
  tokenMarketDetails: RFQTokenMarketDetailsResult.optional(),
  statuses: z.array(RFQStatusItemResult),
  rfq: RFQResult.nullable(),
})

export type RFQDetails = z.infer<typeof RFQDetailsResult>

export const ExchangeResult = OptionResult.extend({
  url: z.string().nullable().optional(),
  type: z.enum(ExchangeTypeEnumValues),
  order: z.number().nullable(),
})

export type Exchange = z.infer<typeof ExchangeResult>

export const ExchangesResult = z.array(ExchangeResult)

export const MarketMakerResult = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  imageUrl: z.string().nullable(),
  engagementOptions: z.array(z.enum(EngagementOptionEnumValues)),
  supportedCEX: ExchangesResult,
  supportedDEX: ExchangesResult,
  services: z.array(z.string()),
  rank: z
    .object({
      marketMakerId: z.string(),
      marketMakerName: z.string(),
      imageUrl: z.string(),
      rank: z.number(),
      depthRank: z.number(),
      volumeRank: z.number(),
      spreadRank: z.number(),
      projectsTracked: z.number(),
      avgProjectFDV: z.number(),
      tier1: z.number(),
      tier2: z.number(),
      tier3: z.number(),
    })
    .nullish(),
})

export type MarketMaker = z.infer<typeof MarketMakerResult>

export const MarketMakersResult = z.array(MarketMakerResult)

export const MarketMakerRFQBody = z.object({
  country: z.string().min(2, 'Country is required'),
  isTokenIssuedInUsTerritory: z.boolean().nullable().optional(),
  hasLegalOpinionOnUtilityStatus: z.boolean({
    message: 'Legal opinion is required',
  }),
  externalCapital: z.string().min(1, 'The Amount of external capital is required'),
  investors: z.array(z.string().uuid()).optional(),
  preferMarketMakerQuality: likertScaleTypeSchema,
  preferLiquidityDepth: likertScaleTypeSchema,
  preferTokenPurchasePrice: likertScaleTypeSchema.nullable().default(null),
  preferStablecoinEfficiency: likertScaleTypeSchema.nullable().default(null),
  motivations: z.array(z.string()).min(1),
  exchangesCEX: z.array(z.string()),
  exchangesDEX: z.array(z.string()),
  otherCex: z.array(z.string()),
  otherDex: z.array(z.string()),
  marketMakersQty: z.number().min(1).max(5),
  contactType: z.enum(ContactTypeEnumValues),
  contact: z.string().min(1, 'Contact is required'),
  engagementOptions: z.array(z.enum(EngagementOptionEnumValues)).min(1),
  tokenSupplyPercentageToAllocate: z.string().min(1),
  stableCoinBudgetToAllocate: z.string().min(1).nullable(),
  otherMarketMakers: z.array(z.string()),

  calendarLink: z.string().url().optional(),
  hasMarketMakerEngagement: z.boolean(),
  mts: z.number().min(0),
  fdv: z.number().min(0).nullable(),
  tokenomicsUrl: z.string().url().optional(),
  coingeckoUrl: z.string().url().optional(),
  percentageUnlockedAtTge: z.number().optional().nullable().default(10),
  shareTdPublishedPage: z.boolean().optional().default(false),
  hasCoingeckoUrl: z.boolean().optional().default(false),
  services: z.array(z.string()).min(1),
  confirmedListingExchanges: z.array(z.string()).optional(),
})

export type MarketMakerRFQ = z.infer<typeof MarketMakerRFQBody>

export type MMRFQEmailRequest = {
  projectId: string
  projectName: string
  projectTicker: string | null
  projectLink: string | null
  tokenListed: boolean
  isTdPublished: boolean
  tgeDate: string | null
  coingeckoId: string | null
  investors: string[]
  exchangesCEX: string[]
  exchangesDEX: string[]
  confirmedListingExchanges: string[]
  rfq: Database['core']['Tables']['market_maker_rfqs']['Row']
}

export type MMRFQRoundInReviewEmailRequest = {
  round: number
  projectName: string
  projectTicker: string | null
  mmQty: number
  quotesQty: number
  dateToReply: Date
}

export type MMRFQAcceptQuotesEmailRequest = {
  projectName: string
  round: number
  selection: string[]
}

export type MMRFQAcceptQuotesEmailRequestSelectedMarketMaker = {
  rfqId: string
  projectName: string
  ticker: string
  round: number
  submissions: MMRFQQuoteSubmission[]
}

export type MMRFQAcceptQuotesEmailRequestNotSelectedMarketMaker = {
  rfqId: string
  projectName: string
  ticker: string
  round: number
}

export type MMRFQProjectOwnerForRequoteRequest = {
  projectName: string
  selection: string[]
  date: string
}
export type MMRFQQuoteSubmission = {
  engagementType: string
  quotesNumber: number
}
export type MMRFQMarketMakerRequoteSelectionRequest = {
  projectName: string
  ticker: string
  quotesNumber: number
  date: string
  rfqId: string
  quotes: MMRFQQuoteSubmission[]
}

export type MMRFQMarketMakerRequoteUnselectionRequest = {
  projectName: string
  ticker: string
  rfqId: string
}

export type MMRFQApprovedEmailRequest = {
  projectName: string
  projectTicker: string
  submitByDate: string
  rfqId: string
}

const QuoteKPISchema = z.object({
  quoteId: z.string(),
  metric: z.string(),
  valueTier1Exchanges: z.number(),
  valueTier2Exchanges: z.number(),
  valueTier3Exchanges: z.number(),
  minValue: z.number(),
  maxValue: z.number(),
  minMaxUnit: z.string(),
})

const QuoteDMMSchema = z.object({
  quoteId: z.string(),
  onboardingFeeUsd: z
    .number()
    .int({ message: 'Onboarding Fee value must be a non-negative integer.' })
    .nonnegative()
    .finite(),
  unlimitedExchangeCoverage: z.boolean(),
  unlimitedExchangeCoverageMonthlyFeeUsd: z
    .number()
    .int({ message: 'Monthly Fee value must be a non-negative integer.' })
    .nonnegative()
    .finite(),
  singleExchangeCoverageMonthlyFeeUsd: z
    .number()
    .int({ message: 'Monthly Fee value must be a non-negative integer.' })
    .nonnegative()
    .finite(),
  minContractTermMonths: z.number().int().nonnegative().finite(),
  profitSharePercentage: z.number().nonnegative().finite(),
  loanCustody: z.enum(MarketMakerQuoteLoanCustodyEnumValues),
})

const QuoteTrancheSchema = z.object({
  quoteId: z.string(),
  trancheNumber: z.number(),
  tokenSupplyPercentage: z.number(),
  priceValue: z.number(),
  priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyEnumValues),
  twapThresholdDays: z.number().nullable(),
  timePeriod: z.number(),
})

export const QuoteSchema = z.object({
  id: z.string(),
  rfqId: z.string(),
  projectId: z.string(),
  marketMakerId: z.string(),
  round: z.number(),
  proposal: z.number().nullable(),
  engagementType: z.enum(EngagementOptionEnumValues),
  loanTokenSupplyPercentage: z.number(),
  loanStablecoinQuantity: z.number(),
  loanOptionsStyle: z.enum(OptionsStyleEnumValues).nullable(),
  loanTenorMonths: z.number().nullable(),
  loanInterestRatePercentage: z.number().nullable(),
  kpiOrderBookDominanceThreshold: z.number(),
  status: z.string(),
  selectedForRequote: z.boolean(),
  createdAt: z.string(),
  dmm: z.union([QuoteDMMSchema.nullable(), z.array(QuoteDMMSchema).optional()]),
  kpis: z.array(QuoteKPISchema),
  tranches: z.array(QuoteTrancheSchema).optional(),
})

export type Quote = z.infer<typeof QuoteSchema>

export const ViewSchemaResponse = z.object({
  value: z.number(),
  unit: z.string(),
})

export const MetricSchemaResponse = z.object({
  metric: z.enum(MarketMakerQuoteKpiMetricEnumValues),
  views: z.object({
    percent: ViewSchemaResponse,
    usd: ViewSchemaResponse,
  }),
})

export const TierSchemaResponse = z.object({
  tier: z.string(),
  data: z.array(MetricSchemaResponse),
})

export type TierSchema = z.infer<typeof TierSchemaResponse>
export const RFQQuotesSummary = z.object({
  quotesReceptionDate: z.string().date().optional(),
  quotesCount: z.number(),
  engagements: z.object({
    [EngagementOptionEnum.LoanCallOption]: z.object({
      quotesCount: z.number(),
      avgTokenLoanSize: z.number(),
      avgTokenLoanSupplyPercentage: z.number(),
      europeanOptionsCount: z.number(),
      americanOptionsCount: z.number(),
      avgLoanTenorMonths: z.number(),
      avgTokenLoanSizeUsd: z.number(),
      avgOptionPremiumPerQuote: z.number(),
      avgInterestRatePercentage: z.number(),
      avgNumberOfTranches: z.number(),
      tranchesFixedPricePercentage: z.number(),
      tranchesDynamicPricePercentage: z.number(),
      lessThan1MonthPercentage: z.number(),
      between1And6MonthsPercentage: z.number(),
      greaterThan6MonthsPercentage: z.number(),
      avgOrderBookDominanceMax: z.number(),
    }),
    [EngagementOptionEnum.RetainerWorkingCapital]: z.object({
      quotesCount: z.number(),
      avgTokenLoanSize: z.number(),
      avgTokenLoanSupplyPercentage: z.number(),
      avgStablecoinLoanSizeUsd: z.number(),
      avgTokenLoanSizeUsd: z.number(),
      avgOnboardingFeeUsd: z.number(),
      avgMonthlyFeeUsd: z.number(),
      avgProfitSharePercent: z.number(),
      avgTotalLoanSizeUsd: z.number(),
      quotesWithUnlimitedExchangeCoveragePercantage: z.number(),
      quotesWithFeesChargedPerExchangePercantage: z.number(),
    }),
  }),
})

export type RFQQuotesSummary = z.infer<typeof RFQQuotesSummary>

export const RFQRoundDetailsResponse = z.object({
  quotesSummary: RFQQuotesSummary,
  kpisSummary: z.array(TierSchemaResponse),
})

export type RFQRoundDetails = z.infer<typeof RFQRoundDetailsResponse>

export const LoanCallOptionTranche = z.object({
  trancheNumber: z.number().int().min(0).finite(),
  tokenSupplyPercentage: z.number(),
  priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyEnumValues),
  price: z.number(),
  priceFlag: z.enum(MMRFQTranchePriceFlagArray),
  twapThresholdDays: z.number().nullable(),
  timePeriod: z.number(),
})

export type LoanCallOptionTranche = z.infer<typeof LoanCallOptionTranche>

const BaseQuoteSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(MarketMakerQuoteStatusEnumValues),
  engagementType: z.enum(EngagementOptionEnumValues),
  marketMakerName: z.string(),
  marketMakerId: z.string(),
  marketMakerImageUrl: z.string().nullable(),
  proposal: z.number().int().min(1).finite().nullable(),
  loanTokenSupplyPercentage: z.number(),
  loanTokenQuantity: z.number(),
  loanTokenSizeUsd: z.number(),
  kpiOrderBookDominanceThreshold: z.number(),
  kpiLoanValueDepth200BpsUsd: z.number(),
  projectedLoanUtilizationPercentage: z.number(),
  kpis: z.array(TierSchemaResponse),
  marketMakerComment: z.string().nullable(),
})

export const LoanCallOptionQuoteResponse = BaseQuoteSchema.extend({
  loanOptionsStyle: z.enum(OptionsStyleEnumValues),
  loanTenorMonths: z.number(),
  loanInterestRatePercentage: z.number().nullable(),
  kpiBidAskSpreadBps: z.number(),
  tranchesCount: z.number(),
  tranches: z.array(LoanCallOptionTranche),
})

export type LoanCallOptionQuote = z.infer<typeof LoanCallOptionQuoteResponse>

export const RetainerWorkingCapitalQuoteResponse = BaseQuoteSchema.extend({
  loanStablecoinSizeUsd: z.number(),
  loanCustody: z.enum(MarketMakerQuoteLoanCustodyEnumValues),
  onboardingFeeUsd: z.number(),
  unlimitedExchangeCoverage: z.boolean(),
  monthlyFeeUsd: z.number(),
  minContractTermMonths: z.number(),
  profitSharePercentage: z.number(),
  unlimitedExchangeCoverageMonthlyFeeUsd: z.number().nonnegative().finite(),
  singleExchangeCoverageMonthlyFeeUsd: z.number().nonnegative().finite(),
})

export type RetainerWorkingCapitalQuote = z.infer<typeof RetainerWorkingCapitalQuoteResponse>

export const QuotesByEngagementTypeResponse = z.object({
  [EngagementOptionEnum.LoanCallOption]: z.array(LoanCallOptionQuoteResponse),
  [EngagementOptionEnum.RetainerWorkingCapital]: z.array(RetainerWorkingCapitalQuoteResponse),
})

export type QuotesByEngagementType = z.infer<typeof QuotesByEngagementTypeResponse>

export const QuotesSelectionPreviewResponse = z.object({
  quotes: QuotesByEngagementTypeResponse,
  kpisSummary: z.array(TierSchemaResponse),
  quotesSummary: RFQQuotesSummary,
  selectedQuotesSummary: RFQQuotesSummary,
})

export type QuotesSelectionPreview = z.infer<typeof QuotesSelectionPreviewResponse>

export const QuoteBaseSchema = z.object({
  kpiOrderBookDominanceThreshold: z.number().nonnegative().finite(),
  marketMakerComment: z.string().nullable(),
  loanTokenSupplyPercentage: z.number().positive().finite(),
  kpis: z
    .array(KpiResultSchema)
    .length(5)
    .refine((kpis) => new Set(kpis.map((item) => item.tier)).size === kpis.length, {
      message: 'KPI tiers must be unique',
    }),
  engagementType: z.enum(EngagementOptionEnumValues),
})

export type QuoteBaseSchemaType = z.infer<typeof QuoteBaseSchema>

export const QuoteLoanCallOptionSchemaTranche = z.object({
  trancheNumber: z.number().int().min(1).max(4),
  tokenSupplyPercentage: z.number().positive().finite(),
  priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyEnumValues),
  priceValue: z.number().nonnegative().finite(),
  twapThresholdDays: z.number().int().nonnegative().finite().nullable(),
  timePeriod: z.number().int().nonnegative().finite(),
})

export const QuoteLoanCallOptionSchema = QuoteBaseSchema.extend({
  engagementType: z.literal(EngagementOptionEnum.LoanCallOption),
  loanOptionsStyle: z.enum(OptionsStyleEnumValues),
  loanTenorMonths: z.number().int().nonnegative().finite(),
  loanInterestRatePercentage: z.number().nonnegative().finite(),
  tranches: z
    .array(QuoteLoanCallOptionSchemaTranche)
    .min(1, 'Loan Details are required')
    .max(4)
    .refine(
      (tranches) =>
        tranches
          .toSorted((a, b) => a.trancheNumber - b.trancheNumber)
          .every((tranche, index) => tranche.trancheNumber === index + 1),
      { message: 'Tranches numbers must be sequential and start from 1' },
    ),
})

export const QuoteRetainerWorkingCapitalSchema = QuoteBaseSchema.extend({
  engagementType: z.literal(EngagementOptionEnum.RetainerWorkingCapital),
  loanStablecoinQuantity: z.number().int().positive().finite(),
  dmm: QuoteDMMSchema.omit({ quoteId: true }).refine(
    (data) =>
      data.unlimitedExchangeCoverage
        ? data.unlimitedExchangeCoverageMonthlyFeeUsd > 0
        : data.singleExchangeCoverageMonthlyFeeUsd > 0,
    (data) => ({
      message: 'Number must be greater than 0',
      path: data.unlimitedExchangeCoverage
        ? ['unlimitedExchangeCoverageMonthlyFeeUsd']
        : ['singleExchangeCoverageMonthlyFeeUsd'],
    }),
  ),
})
export const QuoteCreateSchema = z
  .discriminatedUnion('engagementType', [QuoteLoanCallOptionSchema, QuoteRetainerWorkingCapitalSchema])
  .superRefine((data, ctx) => {
    if (data.engagementType === EngagementOptionEnum.LoanCallOption) {
      const sum = data.tranches.reduce((acc, tranche) => acc + tranche.tokenSupplyPercentage, 0)
      const precision = 3
      const factor = Math.pow(10, precision)
      const difference = Math.abs(Math.round((data.loanTokenSupplyPercentage - sum) * factor) / factor)

      if (isFloatLessWithPrecision(data.loanTokenSupplyPercentage, sum, precision)) {
        const msg = `The % of token supply allocated to your tranches has been exceeded by ${difference}%. The SUM of your tranches cannot exceed ${data.loanTokenSupplyPercentage}% of Token Supply.`
        ctx.addIssue({
          path: ['loanTokenSupplyPercentage'],
          message: msg,
          code: ZodIssueCode.custom,
        })

        data.tranches.forEach((_, index) => {
          ctx.addIssue({
            path: ['tranches', index, 'tokenSupplyPercentage'],
            message: msg,
            code: ZodIssueCode.custom,
          })
        })
      } else if (isFloatGreaterWithPrecision(data.loanTokenSupplyPercentage, sum, precision)) {
        const msg = `There is still ${difference}% of the total token supply % pending to be allocated on your tranches. Please adjust the values to continue.`
        ctx.addIssue({
          path: ['loanTokenSupplyPercentage'],
          message: msg,
          code: ZodIssueCode.custom,
        })

        data.tranches.forEach((_, index) => {
          ctx.addIssue({
            path: ['tranches', index, 'tokenSupplyPercentage'],
            message: msg,
            code: ZodIssueCode.custom,
          })
        })
      }
    }
  })

export type QuoteLoanCallOptionSchemaType = z.infer<typeof QuoteLoanCallOptionSchema>
export type QuoteRetainerWorkingCapitalSchemaType = z.infer<typeof QuoteRetainerWorkingCapitalSchema>
export type QuoteLoanCallOptionSchemaTrancheType = z.infer<typeof QuoteLoanCallOptionSchemaTranche>
export type QuoteCreateSchemaType = z.infer<typeof QuoteCreateSchema>
export type QuoteDMMSchemaType = z.infer<typeof QuoteDMMSchema>

export const QuotesSummaryResponse = z.object({
  projectName: z.string(),
  tokenTicker: z.string().nullable(),
  marketMakerName: z.string(),
  loanTenorMonths: z.number().nullable(),
  totalLoanQuantityTokens: z.number().nullable(),
  currentTokenPrice: z.number(),
  tokenPriceW2x: z.number(),
  tier1Depth50Bps: z.number(),
  tier1Depth100Bps: z.number(),
  tier1Depth200Bps: z.number(),
  tier1Spread: z.number(),
  tier1Depth50BpsW2xPx: z.number(),
  tier1Depth100BpsW2xPx: z.number(),
  tier1Depth200BpsW2xPx: z.number(),
  weightedOptionPremiumPercent: z.number(),
  weightedPurchasePrice: z.number(),
  weightedPurchaseAmount: z.number(),
  numberOfTranches: z.number(),
  tranches: z.array(
    z.object({
      percentFixedPriced: z.number().nullable(),
      proceedsFixed: z.number().nullable(),
      percentDynamicPrice: z.number().nullable(),
      twapThreshold: z.number().nullable(),
      timePeriod: z.number().nullable(),
      priceW2x: z.number().nullable(),
      proceedsDynamic: z.number().nullable(),
      quantity: z.number().nullable(),
    }),
  ),
  totalProceeds: z.number(),
  minimumDepth50Bps: z.number(),
  minimumDepth100Bps: z.number(),
  minimumDepth200Bps: z.number(),
  minimumSpread: z.number(),
  maximumDepth50Bps: z.number(),
  maximumDepth100Bps: z.number(),
  maximumDepth200Bps: z.number(),
  maximumSpread: z.number(),
  orderBookDominance: z.number(),
  totalLoanQuantityStablecoins: z.number().nullable(),
  minimumContractTermMonths: z.number().nullable(),
  onboardingFee: z.number().nullable(),
  monthlyFeePerExchange: z.number().nullable(),
  monthlyFeeUnlimitedExchanges: z.number().nullable(),
  profitSharePercent: z.number().nullable(),
})

export const RFQSurveySchema = z.object({
  leaderboardRankInfluence: likertScaleTypeSchema,
  loanSizeImportance: likertScaleTypeSchema,
  liquidityKpiInfluence: likertScaleTypeSchema,
  loanPricingSensitivity: likertScaleTypeSchema,
  feesInfluence: likertScaleTypeSchema,
  profitShareInfluence: likertScaleTypeSchema,
  improvedLiquidityKpisAcceptance: likertScaleTypeSchema.optional().nullable().default(null),
  lessFavorablePricingAcceptance: likertScaleTypeSchema.optional().nullable().default(null),
})

// TODO: make survey required when it will be done.
export const RFQAcceptSchema = z.object({
  round: z.number().int().min(1).max(2),
  quoteIds: z
    .array(z.string().uuid())
    .min(1, 'Provide at least one quote to accept.')
    .max(5, 'You are able to accept for up to 5 quotes from the pool of proposals.'),
  survey: RFQSurveySchema.optional(),
})

export const RFQRequoteSchema = z.object({
  quoteIds: z
    .array(z.string().uuid())
    .min(1, 'Provide at least one quote to requote.')
    .max(5, 'You are able to requote for up to 5 quotes from the pool of proposals.'),
  survey: RFQSurveySchema.optional(),
})

export type RFQAcceptSchemaType = z.infer<typeof RFQAcceptSchema>
export type RFQRequoteSchemaType = z.infer<typeof RFQRequoteSchema>
export type RFQSurveySchemaType = z.infer<typeof RFQSurveySchema>
